import * as React from "react";
import { Link, graphql } from "gatsby";
import {
  Layout,
  SEO,
  CJournalJumbotron,
  CBreadCrumb,
  CJournalLead,
  CProfileMedia,
  CJournalText,
  CWideImgMedia,
  CFloatingImg,
  CBtnList,
  LWrap,
  CQuoteBox,
  CSingleImg,
  CChapterHeading,
  CLabelTextList,
  CJournalMedia,
  CMedia,
  CTileImg,
} from "../../../components/_index";
import { text } from "stream/consumers";

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  console.log(data.markdownRemark);
  return (
    <Layout isKv={false}>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CBreadCrumb
        data={{
          parent: [
            {
              label: "pickup",
              path: "/pickup/",
            },
          ],
          current: {
            label: frontmatter?.title.includes("-")
              ? frontmatter?.title.split("-")[0]
              : frontmatter?.title,
          },
        }}
      />
      <CJournalJumbotron
        data={{
          label: "ザ ロイヤルパークホテル アイコニック 京都",
          date: "2022年06月30日",
          title: (
            <>
              五感を刺激する、
              <br className="u_sp" />
              あたらしい
              <br className="u_sp" />
              京都ホテルステイ
            </>
          ),
          img: {
            src: "/assets/images/journal/article04/kv.png",
          },
          imgSp: {
            src: "/assets/images/journal/article04/kv__sp.png",
          },
        }}
      />
      <div className="u_pb60">
        <LWrap size="small">
          <CJournalLead
            lead={
              <>
                <p>
                  もしも平日に休みを取って、どこかへ行けるとしたら、あなたはどこへ行くだろう。
                  友人と食事へ、買い物へ、癒しを求めて自然の中へ？いつもと違う新しいリフレッシュがしたいならば、ホテルという選択肢はどうだろう。充実したホテルの設備とおもてなしにたっぷりと一人向き合う時間。忙しい毎日をときほぐし、
                  “わたしらしさ”を取り戻すために、ホテルを訪れてみるのもいいだろう。
                </p>
                <p>
                  今回は2022年4月にオープンしたばかりのザ ロイヤルパークホテル
                  アイコニック
                  京都で非日常を体験。せわしない毎日から解放される、あたらしい京都のホテルステイをご紹介。
                </p>
              </>
            }
          />
        </LWrap>
      </div>
      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            img: {
              src: "/assets/images/journal/article04/img_chapter01.png",
            },
            imgSp: {
              src: "/assets/images/journal/article04/img_chapter01__sp.png",
            },
            label: {
              en: "CHAPTER",
              number: "01",
            },
            title: (
              <>
                <span>午後の京都、</span>
                <span>ホテルへの扉を開く</span>
              </>
            ),
          }}
        />
        <LWrap>
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    午後の日差しに照らされ、ふり注ぐ緑のシャワー。初夏の京都の街並みは、青々とした緑と凛とした佇まいに背筋が自然とシャンとする。京都駅から電車で5分ほど。地下鉄烏丸御池駅に降り立ち、目抜き通りである「御池通」を歩けばそこは都会らしさと京の風情が融合した街。現代的な建物が並ぶが、ひとたび小さな路地に入ると京都らしい町屋が現れる。
                  </p>
                  <p>
                    「あの素敵な建物はなんだろう。後で散歩でもしてみようかな」
                    <br />
                    期待と想像を膨らませながら、昨日までの仕事の忙しさをリセットするためホテルへ向かう。
                  </p>
                </>
              ),
            }}
          />
          <CLabelTextList
            direction="column"
            data={[
              {
                label: "#15:00",
                text: (
                  <>
                    大きな窓と真っ白でスタイリッシュな外観、ここがザ
                    ロイヤルパークホテル アイコニック
                    京都だ。早速ホテルの中へと足を踏み入れるとこちらもまたスタイリッシュで開放的なロビー。シンプルかつ落ち着いた色彩で統一され、まさに洗練された空間だ。
                  </>
                ),
              },
            ]}
          />
          <CTileImg
            size="small"
            data={[
              {
                img: {
                  src: "/assets/images/journal/article04/img_chapter01_01.png",
                  alt: "",
                },
                caption: (
                  <>
                    天井高約7mのロビーを囲むようにバーやレストラン、パティスリーがある
                  </>
                ),
              },
              {
                img: {
                  src: "/assets/images/journal/article04/img_chapter01_02.png",
                  alt: "",
                },
                caption: (
                  <>
                    陶アート「□」（作：陶額堂）。自分自身を認識するための「空」をモチーフにした作品
                  </>
                ),
              },
            ]}
          />
          <CJournalText
            data={{
              text: (
                <>
                  随所に見られるセンスの良さはアートにも。「京を知り、禅に通ずる。」というテーマのもと、「禅」の精神を表現した作品が展示されているらしい。京都の伝統技法や京都にゆかりのある作家たちによるアートは、ホテルの中にいながらも旅気分を味わえる。
                </>
              ),
            }}
          />
          <CJournalText
            data={{
              text: <>スムーズな流れでチェックインを済ませ、お部屋へ。</>,
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article04/img_chapter01_03.png",
              alt: "",
            }}
            caption={
              <>
                オフィスビルに囲まれた場所にありながらこの開放感。全室白と木を基調にしたインテリアで京都の要素も随所に
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    今回は人気のコーナーバルコニーを予約した。スタイリッシュで洗練されているけれど、どこか落ち着いていてまるで家にいるような安らぎを感じる。
                  </p>
                  <p>
                    バスルームには贈り物のような木製のボックスが。ゆっくりと開いてみるとアメニティの数々が並べられてある。こういうきめ細やかなこだわりに、おもてなしの心を感じる。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article04/img_chapter01_04.png",
              alt: "",
            }}
            caption={
              <>
                再生プラスチックを使用したものをはじめ、環境にも配慮したアメニティ。SDGsを意識した感度の高さもうかがえる
              </>
            }
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article04/img_chapter01_05.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  客室のバルコニーに出ると、若々しい緑の香りがする。「気持ちいい」心地よさに思わず心の声が漏れる。
                </>
              ),
            }}
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            img: {
              src: "/assets/images/journal/article04/img_chapter02.png",
            },
            imgSp: {
              src: "/assets/images/journal/article04/img_chapter02__sp.png",
            },
            label: {
              en: "CHAPTER",
              number: "02",
            },
            title: (
              <>
                <span>おもてなしに心ほぐれる</span>
                <span>スイーツタイム</span>
              </>
            ),
          }}
        />
        <LWrap>
          <CLabelTextList
            direction="column"
            data={[
              {
                label: "#15:30",
                text: (
                  <>
                    そういえばチェックインの時にホテルスタッフがこんなことを言っていた。
                  </>
                ),
              },
            ]}
          />
          <CJournalText
            data={{
              text: (
                <>
                  「宿泊中ご利用いただけるラウンジでは、パティスリー『サダハル・アオキ・パリ』のアフタヌーントランクをご用意しております」
                  <br />
                  うっとりするような一言一言に、心がワクワクしてくる。どんな素敵な体験ができるのか、ホテルの2階にある宿泊者用ラウンジ「アイコニックラウンジ」へと向かう。
                </>
              ),
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <ul className="c_noteList">
                    <li>
                      約200平米のラウンジは「SAJIKI」「OKU」「TERRACE」の3エリア。過ごしたいスタイルによって好みの空間を選べる
                    </li>
                  </ul>
                </>
              ),
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  心地よさと快適さを兼ね備えた雰囲気に大きな窓が印象的なアイコニックラウンジ。夏は緑のカーテンが目に優しいけれど、秋や冬にきてもまた違った風情があって素敵かもしれない。そんなことを考えているとアフタヌーントランクが運ばれてきた。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article04/img_chapter02_01.png",
              alt: "",
            }}
            caption={
              <>
                贅沢なスイーツセット（写真は二名様分）。ミニカヌレと抹茶マドレーヌは烏丸御池店限定（※1）
                <br />
                <span className="u_referenceMark">
                  1.時期により提供内容が異なります。
                </span>
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  小さなトランクの中には色合い鮮やかなスイーツが。マカロンとカヌレ、ミニケーキに焼き菓子。ちょっとずつ、いろいろ食べられるのが嬉しい。
                </>
              ),
            }}
          />
          <CTileImg
            size="small"
            data={[
              {
                img: {
                  src: "/assets/images/journal/article04/img_chapter02_02.png",
                  alt: "",
                },
              },
              {
                img: {
                  src: "/assets/images/journal/article04/img_chapter02_03.png",
                  alt: "",
                },
              },
            ]}
            caption={
              <>
                アフタヌーントランクはアイコニック京都オリジナル。中に入るスイーツは1階の「サダハル・アオキ・パリ
                烏丸御池店」でも購入可能（※2）
                <br />
                <span className="u_referenceMark">
                  2.一部スイーツはサイズを変更して販売しております。
                </span>
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  甘いものを口にすれば、もうそれだけで顔がほころぶ。ラウンジに設置されたドリンクバーで紅茶やコーヒー、ジュースにワイン、好きなドリンクを一緒に楽しみながら、到着早々ホテルのおもてなしに心がほぐれる。旅のワクワク感は、こうでなければ。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article04/img_chapter02_04.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  そういえば、ずっと読みたかった本があった。鞄に忍ばせてきた本を開き、スマホの電源はオフにしてじっくりインプットする時間。ラウンジの心地よい雰囲気に包まれて、自分だけにフォーカスできる。
                </>
              ),
            }}
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            img: {
              src: "/assets/images/journal/article04/img_chapter03.png",
            },
            imgSp: {
              src: "/assets/images/journal/article04/img_chapter03__sp.png",
            },
            label: {
              en: "CHAPTER",
              number: "03",
            },
            title: (
              <>
                <span>日常から自分を解放する</span>
                <span>“メディテーション”</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CLabelTextList
            direction="column"
            data={[
              {
                label: "#17:00",
                text: (
                  <>
                    実は今回のホテルステイ最大のお目当てはホテル内で体験できるメディテーション。どうやら五感を研ぎ澄ます新感覚の瞑想ができるらしく、いろいろリセットしたい私にはピッタリだ。
                  </>
                ),
              },
            ]}
          />
          <CTileImg
            size="small"
            data={[
              {
                img: {
                  src: "/assets/images/journal/article04/img_chapter03_01.png",
                  alt: "",
                },
              },
              {
                img: {
                  src: "/assets/images/journal/article04/img_chapter03_02.png",
                  alt: "",
                },
              },
            ]}
          />
          <CJournalText
            data={{
              text: (
                <>
                  2階のウェルネスエリアには大浴場とメディテーションルームがある。さっきの光溢れるラウンジとは打って変わって、ここはほの暗い照明で照らされた不思議な空間だ。「おすすめの過ごし方」によると、大浴場で身体の芯まで温まってリラックス、それからメディテーションルームに入ってさらに頭と身体を休めるようだ。
                  大浴場で身体を温めること15分。湯上がりで少し軽くなった足取りで「blank」のネオンサインが光る、メディテーションルームへ。
                </>
              ),
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  扉を開けるとそこは、真っ白なミスト（霧）に包まれた空間。
                  シューっというミストの噴出音とともに、サウンドが流れ、少しひんやりとした床を素足のまま進んでいく。ルームの中央にあるクッションの上に腰掛けて、ぼんやりと全体を眺める。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article04/img_chapter03_03.png",
              alt: "",
            }}
            caption={
              <>
                ミストはほんのりと冷たい。大浴場で温まった身体にちょうど良い温度だ
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  「なんだか、異世界にいるみたい」
                  <br />
                  ぼぅっとしていると、部屋の色がだんだんと柔らかなピンク色に変化してきた。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article04/img_chapter03_04.png",
              alt: "",
            }}
            caption={
              <>
                ライトの色は七色に変化する。色が変わるたび部屋の雰囲気がガラリと変わり、感覚を刺激してくれる
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  目を閉じると、いつものように仕事のことを考えてしまう。休み明けにあれをしなきゃ、あの人に連絡しておいた方がいいかしら…。いけない、今日は自分のための時間にするんだった。
                  <br />
                  また目を閉じて、深呼吸をして、自分の呼吸だけに意識を向ける。頬にあたるひんやりとしたミスト、足から伝わる湿った床の心地よさ、七色の光と音—。だんだんと仕事のことは忘れて自分の身体の感覚を感じられるようになってくる。
                </>
              ),
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>「blank」での不思議な体験を終えて、次は隣の「tea」へ。</p>
                  <p>
                    こちらもまた、ほの暗い空間の中に無造作に置かれたテーブルが広がる空間。天井に映し出される水紋と水の音が「blank」とはまた違う雰囲気を醸している。部屋の隅に置かれた茶の香りを楽しむ。
                  </p>
                  <p>
                    「この香り、好きかも」
                    <br />
                    好きな茶葉を選んだらお茶を淹れて好きな席へと移動する。
                  </p>
                </>
              ),
            }}
          />
          <CTileImg
            size="small"
            data={[
              {
                img: {
                  src: "/assets/images/journal/article04/img_chapter03_05.png",
                  alt: "",
                },
              },
              {
                img: {
                  src: "/assets/images/journal/article04/img_chapter03_06.png",
                  alt: "",
                },
              },
            ]}
            caption={
              <>
                お茶の種類はrelaxの「半発酵ほうじ茶」とfocusの「アールグレイ煎茶」2種
              </>
            }
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article04/img_chapter03_07.png",
              alt: "",
            }}
            caption={
              <>
                天井に映し出される水紋。鏡面になっているテーブルにもその姿が映し出されなんともいえない幻想感
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  つい急ぎ足になってしまう日々の中で、お茶をじっくり味わったのはいつぶりだっただろう。ふわりと香るお茶の香が鼻に抜け、甘みと深み、渋みが舌の上を通る。最後の一滴まで感覚を研ぎ澄ませて味わうと、時間の流れがほんの少しだけいつもよりも緩まってくるように感じる。
                </>
              ),
            }}
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            img: {
              src: "/assets/images/journal/article04/img_chapter04.png",
            },
            imgSp: {
              src: "/assets/images/journal/article04/img_chapter04__sp.png",
            },
            label: {
              en: "CHAPTER",
              number: "04",
            },
            title: (
              <>
                <span>京の夜を彩る時間、</span>
                <span>大人の「遊び」と「緩み」</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CLabelTextList
            direction="column"
            data={[
              {
                label: "#19:00",
                text: (
                  <>
                    メディテーションを体験した後、少しだけ部屋で横になった。30分ほどの短い眠りだったけれどいつにも増してすっきりとした目覚め。頭の中がクリアになって、身体も心なしか軽い。さあ、夜の楽しみはまだこれから。ラウンジに寄ってアペタイザーを楽しもうかな。
                  </>
                ),
              },
            ]}
          />
          <CJournalText
            data={{
              text: (
                <>
                  アイコニックラウンジ「TERRACE」にはファイヤーピットが。夕方から火が灯され、大人な雰囲気へと様変わり
                </>
              ),
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  ラウンジのドリンクバーでワインを選び、ファイヤーピットの目の前に。ラウンジに集う人々の思い思いに語り合う姿や炎のゆらめきをぼんやりと眺めていると、不思議と温かい気持ちになる。誰にも干渉されない、心地よい孤独な時間はなんて贅沢なのだろう。
                </>
              ),
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  ディナーへ出かけようと1階へと降り立つと、ロビーで思わず足を止めてしまった。昼間にも感動したロビーが、ひときわムーディな煌めきを放っているのだ。
                  <br />
                  「ちょっと一杯、飲んでいこうかな」そんなことを思いつき、バーカウンターへ。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article04/img_chapter04_01.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  バーに一人で来るのは初めて。少し緊張しているとバーテンダーがやってきて優しく話しかけてくれたので、おすすめのカクテルを注文してみる。
                  <br />
                  「こちらは「アルテミシア」というカクテルです。アルテミシアはギリシャ神話の女神の名前でもあり、よもぎの学名。女性の健康を手助けすることから、名付けられたんですよ」
                </>
              ),
            }}
          />
          <CTileImg
            size="small"
            data={[
              {
                img: {
                  src: "/assets/images/journal/article04/img_chapter04_02.png",
                  alt: "",
                },
              },
              {
                img: {
                  src: "/assets/images/journal/article04/img_chapter04_03.png",
                  alt: "",
                },
              },
            ]}
            caption={
              <>
                定番カクテルだけでなく京都ならではの和酒を使ったオリジナルカクテルも。ホテルバーだが気軽さがあるのが良い
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  「おいしい」
                  <br />
                  真っ白なカクテルに舌鼓しながら、バーテンダーとの会話が弾む。こんなに自然に笑っている自分って久しぶりかも。なんだか今日は本当に気分がいい。
                </>
              ),
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  京都の街でディナーを終えて、お腹も心も満たされて、すっかりほろ酔い気分に。少しだけ酔いをさましたくて京の街をふらりと散歩してみると、夏の風物詩鴨川の川床がもう出ている。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article04/img_chapter04_04.png",
              alt: "",
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article04/img_chapter04_05.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    ぼんやりと歩いていると草木の匂いや川の音を感じる。子どもの頃にタイムスリップしたような気分に懐かしさを覚えつつ、満たされた気分になる。
                  </p>
                  <p>
                    「お部屋に戻ったら、就寝前のメディテーションをしてみよう」
                    <br />
                    そんなことを考えながら、充実した一日の締めくくりを噛みしめる。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article04/img_chapter04_06.png",
              alt: "",
            }}
            caption={
              <>
                全客室に完備されたオリジナルの音声ガイダンスとアロマオイル。就寝前のリラックスで心地良い眠りへと誘われる
              </>
            }
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            img: {
              src: "/assets/images/journal/article04/img_chapter05.png",
            },
            imgSp: {
              src: "/assets/images/journal/article04/img_chapter05__sp.png",
            },
            label: {
              en: "CHAPTER",
              number: "05",
            },
            title: (
              <>
                <span>心にも身体にも</span>
                <span>朝のエネルギーチャージを</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CJournalText
            data={{
              text: (
                <>
                  お部屋のカーテンを開けると朝の光がたっぷりと注ぎ込まれ、心地良い目覚めが訪れる。窓が大きい客室ならではだ
                </>
              ),
            }}
          />
          <CLabelTextList
            direction="column"
            data={[
              {
                label: "#8:00",
                text: (
                  <>
                    もうひとつ楽しみにしていたことがある。それは、ホテルでいただく朝食だ。ホテル1階のレストラン「シンクロニア
                    ディ
                    シンジハラダ」では、ミシュラン15年連続掲載のシェフが手がける朝食をいただくことができる。東京・銀座のリストランテ「アロマフレスカ銀座」の原田
                    慎次オーナーシェフが手がけた関西初出店のレストラン。朝食の内容は東京と京都そして各地の食材を共鳴・同調(シンクロ)させた新感覚のもの。原田シェフの朝食が食べられるのはここだけのようで、どんな朝食なのか朝から気持ちが高揚する。ワクワクとしながらレストランへ。
                  </>
                ),
              },
            ]}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    朝食は3種のメインから一つを選ぶスタイル。御飯と豚汁、野菜のパン粥、そして…朝パスタだ。「どれが一番人気ですか？」と尋ねると、
                  </p>
                  <p>
                    「どれも小鉢6種と相性のいいものになっています。みなさんお好みですが、ご飯または朝パスタを選ばれる方が多いですね」
                    <br />
                    爽やかな笑顔で応えてくれるレストランスタッフの言葉に心は決まった。「朝パスタ」をいただこう。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article04/img_chapter05_01.png",
              alt: "",
            }}
            caption={
              <>
                彩り豊かな品々は季節によって内容が異なる。メインに京都・滋賀の作家による和食器を取り入れるのも原田シェフのこだわり
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    運ばれてくるやいなや、そのボリュームと豪華さに驚く。シェフがこだわり抜いた牛乳と日本茶、「アロマフレスカ銀座」のシグネチャーメニューも取り入れた小鉢6種とメイン、それに甘味。おいしいものを少しずつ。いろいろ食べられるなんて朝から幸せだ。
                  </p>
                  <p>
                    和と洋の要素がまさにシンクロした料理。九条ネギやあなご、めひかりなど京都や西日本エリアの食材も多く使われている。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article04/img_chapter05_02.png",
              alt: "",
            }}
            caption={
              <>
                朝パスタはトマトとハーブたっぷりのスパゲッティ。フレッシュなハーブの香りとさっぱりとした味わいなので小鉢との相性もいい
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  一口ごとに感動が溢れる朝食に「おいしい」という感覚が自然と湧き出てくる。大切に、丁寧においしい朝食をいただく。朝から感性が刺激され、心、身体躍る、食体験だ。
                </>
              ),
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  朝食を終えたら本を読んだり、もう一度メディテーションルームで瞑想をしたりして過ごす。残りわずかな滞在を思う存分楽しむために、テレビもスマホもオフにしてひたすらに自分時間を楽しむ。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article04/img_chapter05_03.png",
              alt: "",
            }}
          />
          <CLabelTextList
            direction="column"
            data={[
              {
                label: "#10:00",
                text: (
                  <>
                    <p>
                      名残惜しい気もするけれどすっかりリフレッシュができた充実感はたっぷりある。チェックアウトをして旅は終了だ。外へ出ると、空は青々として程よい暑さ。昨日訪れた時とさほど変わらない気候なのに、こんなに心地いいのはなぜだろう。街路樹の緑も、初夏の空気も京の街並みや音も、すべてが昨日とは違って感じる。
                    </p>
                    <p>
                      ここにきて本当によかった。また心がカサついたら、自分を取り戻したくなったら、あたらしい自分に出会いたくなったらここへこよう。そんなことを思いながら、一歩を歩み出す。
                    </p>
                  </>
                ),
              },
            ]}
          />
          <CQuoteBox>
            <h3 className="c_headingLv3">
              パティスリー「サダハル・アオキ・パリ 烏丸御池店」
            </h3>
            <p>
              営業時間：10:00～19:00
              <br />
              <span className="u_referenceMark">
                ご購入いただいた商品はお部屋でもお召しあがりいただけます。
              </span>
            </p>
          </CQuoteBox>
          <CQuoteBox>
            <h3 className="c_headingLv3">
              イタリア料理「シンクロニア ディ シンジハラダ」
            </h3>
            <p>
              営業時間：朝食 7:00～10:30　ランチ 11:30～15:00　ディナー
              17:30～22:00
              <br />
              <span className="u_referenceMark">
                朝食はご宿泊者のみ。ランチ、ディナーはどなたでもご利用いただけます。
              </span>
            </p>
          </CQuoteBox>
          <CJournalText
            data={{
              text: (
                <>
                  <ul className="c_noteList">
                    <li>本記事・画像は2022年6月時点のものです。</li>
                  </ul>
                </>
              ),
            }}
          />

          <p className="u_fwb u_tac u_mt80">
            ザ ロイヤルパークホテル アイコニック 京都
          </p>
          <CBtnList
            exClass="u_mt20"
            data={[
              {
                label: "ホテルサイト",
                color: "bgBlack",
                link: {
                  href: "https://www.royalparkhotels.co.jp/ic/kyoto/",
                  blank: true,
                },
                icon: "blank",
              },
            ]}
          />
        </LWrap>
      </section>

      <div className="l_sect02 u_bgGray">
        <LWrap>
          <CBtnList
            data={[
              {
                label: "ロイヤルパークホテルズJournal",
                link: {
                  href: "/pickup/#journal",
                },
                iconBefore: true,
                icon: "arrowReverse",
              },
            ]}
          />
        </LWrap>
      </div>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
